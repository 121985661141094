import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Link } from "gatsby";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {PostCard} from '../components/Blog';
import AppLayout from '../components/AppLayout';
import Footer from '../components/Footer';
import { MOBILE, TABLET } from '../constants/responsive';
import icons from '../images/categoryIcons';
import Pagination from '../components/Pagination';
import SubscribeForm from '../components/common/SubscribeForm';
import SelectCategories from '../components/Blog/SelectCategories';

const PageContainer = styled('div')`
  margin: 0px auto;
  min-height: 100vh; 
  width:100%;
  background:var(--main-color-white);
  display:flex;
  flex-direction:column;
  align-items:center;  
 `; 
const Container = styled('main')`
  width:100%;
  display: grid;
  grid-template-columns: 27rem 1fr;
  margin: 0 auto;
  padding-top: 19.3rem;
  padding-bottom:12rem;
  gap:3rem;
  @media (min-width:${MOBILE}) and (max-width:${TABLET}) {
		grid-template-columns: 22rem 1fr;
    padding-top: 13rem;
    padding-bottom:11rem;    
  }; 
  @media (max-width:${MOBILE}) { 
    width:-webkit-fill-available;   
    grid-template-columns: 1fr;
    gap: 2.4rem;
    padding-top: 8rem;
    padding-bottom:4rem;
  };
`;
const PostsContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  gap: 3.1rem; 
  @media (max-width:${MOBILE}) { 
    justify-content:center;
  }; 
`;
const CatContainer = styled('div')`
  display: flex;
  flex-direction: column;  
  gap:4px;
  width: -webkit-fill-available;
  h1 {
    margin-bottom: 1.2rem;
  }; 
`;
const MainContainer = styled('div')`
   display: flex;
   flex-direction: column;
   gap: 4rem;
`;
const Button = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:1rem;
  padding: 0.8rem;
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--main-color-black);
  background: var(--bg-color-grey);
  border: none;
  border-radius: 4px; 
`;
const active = css`
  background:var(--bg-color-red)!important;
  color:var(--main-color-white)!important;
`;
const SubscribeContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap:2.4rem;
  align-items:center;
  justify-content: center;
  margin: 0 auto;
  height: 16.8rem;
  width: 100%;
  background-color: var(--bg-color-red);
  h3 {
    color:var(--main-color-white);
  };
  @media (max-width:${MOBILE}) {
    padding: 2.4rem 0;    
    flex-direction:column;
    gap:1.6rem;
    height: auto;
  };
`;     

const categories = [ 
  'WEBDEV',
  'SEOPROMOTION',
  'MARKETINGAUTOMATION',
  'PPCSERVICES',
  'GRAPHICDESIGN',
  'SOCIALMEDIA',
  'CONTENTPRODUCTION'
];

 const categoryMapper = {
  'WEBDEV': 'Web Development',
  'SEOPROMOTION': 'Search Engine Optimization',
  'MARKETINGAUTOMATION': 'Data Science & Automation',
  'PPCSERVICES': 'Paid Advertising',
  'GRAPHICDESIGN': 'Branding',
  'SOCIALMEDIA': 'Social Media Marketing',
  'CONTENTPRODUCTION': 'Content Production'
 };

const POSTS_PER_PAGE = 6;

function Blog({ location, navigate, pageContext: { allPosts } }) {
  console.log(allPosts)
  const [posts, setPosts] = useState([]);
  const [postsToShow, setPostsToShow] = useState([]);
  const breakpoints = useBreakpoint();
  useEffect(() => {
    if (allPosts) {
      const visiblePosts = allPosts.filter(post => post.visibility && new Date(post.publishAt).getTime() <= new Date().getTime());
      setPosts(visiblePosts);
      setPostsToShow(visiblePosts.slice(0, POSTS_PER_PAGE));
    }
  }, [ allPosts ]);

  const [currentPage, setCurrentPage] = useState(0);

  const [isCategory, setIsCategory] = useState(null);

  const onCategoryChange = (categ) => {
    if (categ === isCategory) return;
    setIsCategory(categ);
    setCurrentPage(0);
    const visiblePosts = allPosts.filter(
      post => post.visibility
        && new Date(post.publishAt).getTime() <= new Date().getTime()
        && categ ? post.categories === categ : true);
      setPosts(visiblePosts);
      setPostsToShow(visiblePosts.slice(0, POSTS_PER_PAGE));
  };

  const onPageChange = (page) => {
    setPostsToShow(posts.slice(page * POSTS_PER_PAGE, page * POSTS_PER_PAGE + POSTS_PER_PAGE));
    setCurrentPage(page);
  }

  return (
    <AppLayout location={location} navigate={navigate}>
      <PageContainer>
        <Container className='page-width'>    
          <CatContainer>
            <h1 className='h2'>Blog</h1>           
           { breakpoints.xs 
            ?<SelectCategories onChange={onCategoryChange} isCategory={isCategory}/>
            :<>
              <Button  onClick={() =>onCategoryChange(null)} className={!isCategory && active}>All categories</Button>
              {categories.map((category) => (
                <Button onClick={() => onCategoryChange(category)} key={category} className={isCategory === category && active}>
                  <img src={icons[category]} alt= {icons[category]}/>
                  <span>{categoryMapper[category]}</span>
                </Button>
                ))
              } 
              </>
            }   
          </CatContainer>          
          <MainContainer>
            <PostsContainer>
              {postsToShow && postsToShow.map((post)=>
                <Link key={post.id} to={`/blog/${post.handle}`}>
                  <PostCard post={post}/>
                </Link> )}
            </PostsContainer>
            <Pagination currentPage={currentPage} totalPages={Math.ceil(posts.length / POSTS_PER_PAGE)} pagesToShow={7} handlePageChange={onPageChange} />
          </MainContainer>    
        </Container>
        <SubscribeContainer>
          <h3 className='h2'>Subscribe to our newsletter!</h3>
          <SubscribeForm/>
        </SubscribeContainer>
      <Footer navigate={navigate} />
      </PageContainer>
    </AppLayout>
  )
}

export default Blog;





