import dataScienceIcon from './dataS.svg'
import seoIcon from './seo.svg'
import webIcon from './web.svg'
import advIcon from './adv.svg'
import brandIcon from './brand.svg'
import smmIcon from './smm.svg'
import contentIcon from './content.svg'

const icons = {
  'WEBDEV': webIcon,
  'SEOPROMOTION': seoIcon,
  'MARKETINGAUTOMATION': dataScienceIcon,
  'PPCSERVICES': advIcon,
  'GRAPHICDESIGN': brandIcon,
  'SOCIALMEDIA': smmIcon,
  'CONTENTPRODUCTION': contentIcon,
};

export default icons;