import React from 'react';
import Select from 'react-select';

const optionsListServices = [
  { value: 'ALL', label: 'All categories' },
  { value:'WEBDEV', label: 'Web Development' },
  { value:'SEOPROMOTION', label: 'Search Engine Optimization' },
  { value:'MARKETINGAUTOMATION', label: 'Data Science & Marketing Automation' },
  { value:'PPCSERVICES', label: 'Paid Advertising' },
  { value:'GRAPHICDESIGN', label: 'Branding' },
  { value:'SOCIALMEDIA', label: 'Social Media Marketing' },
  { value:'CONTENTPRODUCTION', label: 'Content Production' },
];
  
const customStyles = {
option: (provided, state) => ({
  ...provided,    
  color: state.isSelected ? 'var(--main-color-white)' : 'var(--main-color-black)',
  backgroundColor: state.isSelected ? 'var(--bg-color-red)' : 'var(--bg-color-grey)',
  padding: '10px 10px',
  margin: '5px 0',    
  borderRadiusTop: '8px',
  borderRadius: '8px',
  '&:hover': {
  backgroundColor: 'var(--main-color-grey)',
  color: state.selectProps.mainColor,
  cursor: 'pointer',
  },
  boxShadow: 'none', 
  '&:last-child': {
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
  },  
}),
container: (provided, state) => ({
  ...provided,
  width: '100%',
  textAlign: 'left',
  color: state.selectProps.mainColor,
  fontFamily: 'inherit',
  fontSize: '12px',
  lineHeight: '140%',
  boxShadow: 'none',
}),
input: (provided) => ({
  ...provided,
  color: 'inherit',
  boxShadow: 'none',
}),
control: (provided, state) => ({
  ...provided,
  border: 'none',
  width: '100%',
  padding: '1px 2px',
  height: '44px',
  background:  state.hasValue ? 'var(--bg-color-red)':'var(--bg-color-grey)',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderRadius: '8px',    
  color: state.hasValue ? 'var(--main-color-white)': 'var(--main-color-black)',
  boxShadow: 'none',
  '&:focus': {
  outline: 'none',      
  },
  '&:hover': {
  borderColor: 'none',
  },
}),

placeholder: (provided, state) => ({
  ...provided,
  textAlign: 'left',
  color: state.selectProps.mainColor ==='var(--main-color-black)' ? 'var(--main-color-black)':'var(--main-color-grey)',
  '&:focus': {
  color: state.selectProps.mainColor,
  },
}),
singleValue: (provided, state) => ({
  ...provided,
  opacity : state.isDisabled ? 0.5 : 1,
  transition : 'opacity 300ms',  
  color: 'inherite'  
}),
indicatorsContainer: () => ({
  position: 'absolute',
  right: 0,
  top: '15%',
}),
dropdownIndicator: (provided, state) => ({
...provided,
color: state.hasValue ? 'var(--main-color-white)': 'var(--main-color-black)',
'&:hover': {
color: state.selectProps.mainColor,
cursor: 'pointer',
},
}),
indicatorSeparator: () => ({
  display: 'none',
}),
menuList: (provided) => ({
  ...provided,
  margin: 0,
  padding: 0,
  border: 0,   
  overflowY: 'visible',
  height: '100%',
  boxShadow: 'none',
}),
menu: (provided) => ({
  ...provided,
  margin: 0,
  padding: 0,
  border: 0,  
  boxShadow: 'none', 
}),
};
  
function SelectCategories({
  themeColor,  
  onChange,
  bgColor,
  isCategory
  }) {
  const onHandleChange = (value) => {
    if (value === 'ALL') {
    onChange(null)
    } else {
    onChange(value);
    }  
  };
  return (
    <Select      
      value={optionsListServices ? optionsListServices.find((option) => option.value === isCategory) : ''}    
      onChange={({ value }) => {onHandleChange(value)}}
      options={optionsListServices}
      styles={customStyles}
      placeholder='categories'      
      isSearchable={false}
      mainColor={themeColor}
      bgColor={bgColor}        
    />
  );
};

  export default SelectCategories;