import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { MOBILE} from '../../../constants/responsive';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { PrimaryBtn} from '../../../components/common';

const themeColor = 'var(--main-color-black)';
const StyledForm = css`
  display: flex;
  flex-direction:row;
  gap:2.4rem;
  align-items:center;
  font-family: inherit;  
  @media (max-width:${MOBILE}) {    
    flex-direction:column;
    gap:1.6rem;
  }; 
`;
const StyledInput = styled('input')`
  box-sizing: border-box;
  padding: 0 1rem;
  width:200px;
  height: 4.1rem;
  border: 2px solid var(--main-color-grey);
  border-radius: 8px;
  background: var(--main-color-white);
  text-align: left;
  color:${themeColor};
  font-size: 16px;
  line-height: 140%;
   &:focus {
    border: 2px solid ${themeColor};
    outline: none;
   };
   &::placeholder{
    color:var(--main-color-black);
    font-size: 16px;
    line-height: 140%;
   };
   &:focus::placeholder{
    color:${themeColor};
   }
`;

const GetConsultationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required')  
});

function SubscribeForm() {
  const onSubmit = async ({ email }) => {
    try {
      await fetch('https://hzpz4lt943.execute-api.us-east-1.amazonaws.com/subscriptions', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
    } catch (err) {
      console.log(err);
    }
  }
  ;
  return (    
      <Formik
        initialValues={{
          email: ''          
        }}
        onSubmit={onSubmit}
        validationSchema={GetConsultationSchema}
      >
        {({ isSubmitting }) => (
          <Form className={StyledForm} id="subscription_form">
            <Field
              id="email"
              name="email"
              placeholder="Email address"
              type="email"
              as={StyledInput}
            />                      
            <PrimaryBtn btnLabel="Submit" disabled={isSubmitting} />
          </Form>
        )}
      </Formik>    
  );
};

export default SubscribeForm;