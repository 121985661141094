import React from 'react';
import styled from '@emotion/styled';
import ArrowNext from '../Icons/ArrowNext';

const Container = styled("div")`
  display: flex;
  justify-content: center;
`;

const Button = styled('button')`
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
`;

const ButtonPage = styled(Button)`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: transparent;
  color: var(--main-color-black);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  &.active {
    background-color: var(--bg-color-red);
    color: #fff;
  }
`;

const ButtonNext = styled(Button)`
  margin-left: 12px;
`;

const getPagesInView = (page, total, pagesToShow) => {
  const pages = Array.from(Array(total).keys());
  const middle = Math.ceil((pagesToShow - 1)/2);
  if (page <= middle) {
    return pages.slice(0, pagesToShow);
  }
  const topLimit = page - middle + pagesToShow;
  if (topLimit >= total) {
    return pages.slice(total - pagesToShow, total);
  }
  return pages.slice(page - middle, topLimit);
}

const Pagination = ({
  currentPage = 0,
  totalPages,
  pagesToShow = 7,
  handlePageChange
}) => {
  const pagesInView = getPagesInView(currentPage, totalPages, pagesToShow);

  const handleClick = (page) => {
    if (page === currentPage) {
      return;
    }
    handlePageChange(page);
  }
  const handlePrevClick = () => {
    if (currentPage === 0) {
      return;
    }
    handlePageChange(currentPage - 1);
  }

  const handleNextClick = () => {
    if (currentPage === totalPages - 1) {
      return;
    }
    handlePageChange(currentPage + 1);
  }

  return (
    <Container>
      {
        pagesInView.map(page => (
            <ButtonPage
              key={page}
              type="button"
              className={page === currentPage && 'active'}
              onClick={() => handleClick(page)}
            >
              {Number(page) + 1}
            </ButtonPage>
          )
        )
      }
      <ButtonNext
        type="button"
        onClick={(handleNextClick)}
      >
        <ArrowNext />
      </ButtonNext>
    </Container>
  )
};

export default Pagination;
