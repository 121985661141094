import React from 'react';
import styled from '@emotion/styled';
import { getImageUrl } from '../../../utils';
import { MOBILE, TABLET } from '../../../constants/responsive';


 const size = [ 269, 219, 138 ];
 const heights = [365, 325, 214 ];

const Container = styled('div')`
	display: flex;
	position:relative;
	flex-direction:column;
	gap:1.6rem;
	width: ${size[0]}px;
	${'' /* height: ${heights[0]}px; */}
	@media (min-width:${MOBILE}) and (max-width:${TABLET}) {
		width: ${size[1]}px;
		${'' /* height: ${heights[1]}px; */}
    gap: 0.8rem;
  }; 
	@media (max-width:${MOBILE}) {
		width: ${size[2]}px;
		${'' /* height: ${heights[2]}px; */}
    gap: 0.35rem;
  };    
`;
const Image = styled('img')`
  border-radius: 8px;  
  height: ${size[0]}px;
  width:100%;
  object-fit: cover;
	@media (min-width:${MOBILE}) and (max-width:${TABLET}) {
		height: ${size[1]}px;
  }; 
	@media (max-width:${MOBILE}) {
		height: ${size[2]}px;
  }; 
`;
const BadgeContainer = styled('div')`
	position:absolute;
	display:flex;
	height:40px;
	width:100%;
	top:0; 
	justify-content: end;
	align-items: center;   
	div {
		max-height:28px;
		margin: 8px 4px 4px;
		padding: 4px;
		border-radius: 4px;
		color: var(--main-color-white);
		background-color: var(--main-color-black);
	}
`;
const BageDuration = styled('div')`
	display:flex;    
	align-items:center;
	justify-content:center;
    
`;
const BageCategory = styled('div')`
  text-align:center;    
`;
const TextContainer = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top:auto;
`
const Exerpt = styled('p')`
  overflow: hidden;
	height: 44px;	
  width: 100%;  
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`;
function clockIcon() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 11.25C4.96165 11.25 3.94662 10.9421 3.08326 10.3652C2.2199 9.78834 1.54699 8.9684 1.14963 8.00909C0.752275 7.04978 0.648307 5.99418 0.85088 4.97578C1.05345 3.95738 1.55347 3.02192 2.28769 2.28769C3.02192 1.55347 3.95738 1.05345 4.97578 0.85088C5.99418 0.648307 7.04978 0.752275 8.00909 1.14963C8.9684 1.54699 9.78834 2.2199 10.3652 3.08326C10.9421 3.94662 11.25 4.96165 11.25 6C11.25 7.39239 10.6969 8.72775 9.71231 9.71231C8.72775 10.6969 7.39239 11.25 6 11.25ZM6 1.5C5.10999 1.5 4.23996 1.76392 3.49994 2.25839C2.75992 2.75286 2.18314 3.45566 1.84254 4.27793C1.50195 5.10019 1.41284 6.00499 1.58647 6.87791C1.7601 7.75082 2.18869 8.55265 2.81802 9.18198C3.44736 9.81132 4.24918 10.2399 5.1221 10.4135C5.99501 10.5872 6.89981 10.4981 7.72208 10.1575C8.54434 9.81686 9.24715 9.24009 9.74162 8.50007C10.2361 7.76005 10.5 6.89002 10.5 6C10.5 4.80653 10.0259 3.66194 9.18198 2.81802C8.33807 1.97411 7.19348 1.5 6 1.5Z" fill="white"/>
        <path d="M7.72125 8.25L5.625 6.15375V2.625H6.375V5.8425L8.25 7.72125L7.72125 8.25Z" fill="white"/>
        </svg>
    )
}
// {categories, duration, exerpt, featuredImage, title}
const categoryMapper = {
  'WEBDEV': 'Web Development',
  'SEOPROMOTION': 'Search Engine Optimization',
  'MARKETINGAUTOMATION': 'Data Science & Automation',
  'PPCSERVICES': 'Paid Advertising',
  'GRAPHICDESIGN': 'Branding',
  'SOCIALMEDIA': 'Social Media Marketing',
  'CONTENTPRODUCTION': 'Content Production'
 };
function PostCard({post}) {
	
    const url = getImageUrl(post.featuredImage);
			
  return (
    <Container>
    {post && <>
    		<BadgeContainer>
        	<BageDuration className='additional'>{clockIcon()}&nbsp;{post?.duration || '5'}&nbsp;<span>min</span></BageDuration>
          {post?.categories&&<BageCategory className='additional'>{categoryMapper[post.categories]}</BageCategory>}
        </BadgeContainer>
        {
            url
            ? <Image src={url} alt={post.featuredImage}/>
            : <div>No image</div>
        }
				<TextContainer>
					<h3>{post?.title}</h3>
       		<Exerpt>{post?.exerpt}</Exerpt>
				</TextContainer>
        
    </>}
    
    </Container>
  )
}


export default PostCard